import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Container, Row, Card, Col, Form as FormTag } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { User, Phone, Mail } from "react-feather";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
import Axios from "axios";

import { GreenButton } from "@components/GreenButton";
import SEO from "@components/SEO";
import { getURLParam } from "@helper";

import styles from "@cssmodules/form.module.scss";

export default function Form() {
    const [isLoading, setIsLoading] = useState(false);
    const [device, setDevice] = useState("");
    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        if (typeof document !== "undefined") {
            document.body.style.background =
                "linear-gradient(#89c6bd, #a5d3c9, #d3dccf, #487275) no-repeat center center fixed";
            document.body.style.backgroundSize = "cover";
        }

        if (isMobile) {
            setDevice("mobile");
        } else {
            setDevice("desktop");
        }

        return () => {
            document.body.style.background = "";
            document.body.style.backgroundSize = "";
        };
    }, []);

    const onSubmit = data => {
        const payload = {
            action: "insert",
            no: "=ROW()-1",
            tanggal: dayjs().format("DD MMMM YYYY HH:mm:ss A"),
            nama: data.nama,
            no_hp: data.no_hp,
            email: data.email,
            source: device,
            keyword: getURLParam("utm_term"),
            campaign_id: getURLParam("utm_campaign"),
        };

        setIsLoading(true);

        Axios.get(
            "https://script.google.com/macros/s/AKfycbxh_BZdGFwggV_OkuKxPFM72TQMOjWj-p-PWZsygogRsMAKqN8c/exec",
            {
                params: payload,
            }
        )
            .then(() => {
                setIsLoading(false);
                navigate("/contact/");
            })
            .catch(error => {
                console.error("Error : ", error);
                setIsLoading(false);
                alert("Terjadi kesalahan. Silahkan coba beberapa saat lagi.");
            });
    };

    return (
        <div className={`${styles.container} mt-4 mt-md-0 py-5`}>
            <SEO title="Form" />
            <Container className="h-100">
                <Row className="h-100 justify-content-center align-items-center">
                    <Col
                        lg={5}
                        md={7}
                        className="d-flex justify-content-center align-items-center"
                    >
                        <Card className={styles.card}>
                            <div className={styles.header}></div>
                            <Card.Body className={styles.body}>
                                <h6
                                    className={`text-center text-muted mb-4 ${styles.title}`}
                                >
                                    Untuk kemudahan berkomunikasi, silakan isi
                                    form di bawah ini:
                                </h6>

                                <h6 className={`text-center ${styles.title2}`}>
                                    Setelah mengisi form, Anda akan menuju
                                    halaman informasi alamat dan nomor telepon
                                    Gudang Seragam
                                </h6>
                                <FormTag
                                    className="px-4"
                                    onSubmit={
                                        !isLoading
                                            ? handleSubmit(onSubmit)
                                            : null
                                    }
                                >
                                    <FormTag.Group>
                                        <div
                                            className={styles.inputIconWrapper}
                                        >
                                            <User
                                                color="gray"
                                                className="mr-2"
                                            />
                                            <FormTag.Control
                                                plaintext
                                                name="nama"
                                                placeholder="Nama"
                                                isInvalid={errors.nama}
                                                className={styles.input}
                                                ref={register({
                                                    minLength: {
                                                        value: 2,
                                                        message:
                                                            "Nama tidak boleh kurang dari 2 karakter.",
                                                    },
                                                    required:
                                                        "Nama tidak boleh kosong.",
                                                })}
                                            />
                                        </div>
                                        <FormTag.Text className="text-danger">
                                            {errors.nama && errors.nama.message}
                                        </FormTag.Text>
                                    </FormTag.Group>

                                    <FormTag.Group>
                                        <div
                                            className={styles.inputIconWrapper}
                                        >
                                            <Phone
                                                color="gray"
                                                className="mr-2"
                                            />
                                            <FormTag.Control
                                                plaintext
                                                name="no_hp"
                                                type="number"
                                                placeholder="No. Hp / Whatsapp"
                                                isInvalid={errors.no_hp}
                                                className={styles.input}
                                                ref={register({
                                                    minLength: {
                                                        value: 10,
                                                        message:
                                                            "No. HP tidak boleh kurang dari 10 karakter.",
                                                    },
                                                    required:
                                                        "No. HP tidak boleh kosong.",
                                                })}
                                            />
                                        </div>
                                        <FormTag.Text className="text-danger">
                                            {errors.no_hp &&
                                                errors.no_hp.message}
                                        </FormTag.Text>
                                    </FormTag.Group>

                                    <FormTag.Group className={styles.mb}>
                                        <div
                                            className={styles.inputIconWrapper}
                                        >
                                            <Mail
                                                color="gray"
                                                className="mr-2"
                                            />
                                            <FormTag.Control
                                                plaintext
                                                name="email"
                                                type="email"
                                                placeholder="E-mail"
                                                isInvalid={errors.email}
                                                className={styles.input}
                                                ref={register({
                                                    required:
                                                        "Email tidak boleh kosong",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message:
                                                            "Mohon masukkan alamat email yang valid.",
                                                    },
                                                })}
                                            />
                                        </div>
                                        <FormTag.Text className="text-danger">
                                            {errors.email &&
                                                errors.email.message}
                                        </FormTag.Text>
                                    </FormTag.Group>

                                    <GreenButton
                                        block
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        <h6 className="mb-0">
                                            {isLoading
                                                ? "MENGIRIM . . . "
                                                : "KIRIM"}
                                        </h6>
                                    </GreenButton>
                                </FormTag>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
